import * as React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'

export default function ProductDetails({ data }) {
  const product = data.product

  return (
    <Layout seoMetaTags={product.seoMetaTags}>
      <div className="pt-6 pb-16 sm:pb-24">
        <nav
          aria-label="Breadcrumb"
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
        >
          <ol role="list" className="flex items-center space-x-4">
            <li key="category">
              <div className="flex items-center">
                <Link
                  to={`/productos/${product.category.slug}`}
                  className="mr-4 text-sm font-medium text-slate-500 hover:text-slate-900"
                >
                  {product.category.name}
                </Link>
                <svg
                  viewBox="0 0 6 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="h-5 w-auto text-gray-300"
                >
                  <path
                    d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </li>
            <li className="text-sm">
              <span aria-current="page" className="font-medium text-gray-900">
                {product.name}
              </span>
            </li>
          </ol>
        </nav>

        <div className="mx-auto mt-8 max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
            <div className="lg:col-span-5">
              <div className="flex justify-between">
                <h1 className="text-xl font-medium text-gray-900">
                  {product.name}
                </h1>
              </div>
            </div>

            {/* Image gallery */}
            <div className="mt-8 lg:col-span-5 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
              <h2 className="sr-only">Imagen producto</h2>

              <div className="grid grid-cols-1">
                {product.photo && (
                  <GatsbyImage
                    image={{ ...product.photo.gatsbyImageData }}
                    alt="foto"
                    className="rounded-lg lg:col-span-2 lg:row-span-2"
                  />
                )}
              </div>
            </div>
            <div className="mt-8 lg:col-span-7">
              {/* Product details */}
              <div className="mt-2">
                <h2 className="text-sm font-medium text-gray-900">
                  Descripción
                </h2>

                <div
                  className="prose prose-sm mt-4 text-gray-500 prose-img:w-1/2 prose-img:m-auto"
                  dangerouslySetInnerHTML={{
                    __html: product.descriptionNode.childMarkdownRemark.html,
                  }}
                />
              </div>

              {product.presentacion && (
                <div className="mt-8 border-t border-gray-200 pt-8">
                  <h2 className="text-sm font-medium text-gray-900">
                    Presentación
                  </h2>

                  <div
                    className="prose prose-sm mt-4 text-gray-500"
                    dangerouslySetInnerHTML={{
                      __html: product.presentacionNode.childMarkdownRemark.html,
                    }}
                  />
                </div>
              )}

              <Link
                to="/contacto"
                className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-sky-900 py-3 px-8 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
              >
                Contacto
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    product: datoCmsProduct(id: { eq: $id }) {
      name
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      presentacion
      presentacionNode {
        childMarkdownRemark {
          html
        }
      }
      category {
        name
        slug
      }
      photo {
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
